import React, { useEffect, useState, useContext } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";
import { addProjectedPart, setPartCategories } from "../../../redux/formSlice";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_PROJECTED_SIMPLE_PARTS_AND_CATEGORY,
  ADD_SIMPLE_PART_AND_CATEGORY,
  ADD_TICKET_ASSET_RETURNED_SIMPLE_PARTS_AND_CATEGORY,
  ADD_TICKET_ASSET_USED_SIMPLE_PARTS_AND_CATEGORY,
  GET_PART_BY_CATEGORY,
  GET_PARTS_CATEGORY_ON_ADDPARTS,
  GET_SIMPLE_LOCATION_LIST,
} from "../../../utls/constants";
import AddQuickPartModal from "./AddQuickPartModal";
import {
  DiagnosisDetailsContext,
  WorkOrderContext,
} from "../../../Context/Inspections/WorkOrderContext";
import { toast } from "react-toastify";
import usefetchData from "../../../hooks/useFetchData";

const AddQuickCategoryModal = ({
  onHide,
  show,
  partCategories,
  action,
  index,
  ticketAssetId,
  reloadCategory,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setCategoryName("");
    setPartName("");
    setQuantity("");
    setCategoryError("");
    setQuantityError("");
    setPartError("");
    if (typeof reloadCategory === "function") {
      reloadCategory();
    } else {
      console.warn("reloadCategory is not defined or not a function");
    }
  }, [show]);

  const [selectedPart, setSelectedPart] = useState("Select Part");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [quantity, setQuantity] = useState("");
  const [partName, setPartName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryModalShow, setCategoryModalShow] = useState(false);
  const [quickCategoryModalShow, setQuickCategoryModalShow] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );
  // Part category search input
  const [partSearch, setPartSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [categoryError, setCategoryError] = useState("");
  const [partError, setPartError] = useState("");
  const [quantityError, setQuantityError] = useState("");

  const [newPartCategory, setNewPartCategory] = useState([]);

  // Filter part data
  // remove parts that are already selected
  const [partId, setPartId] = useState(0);

  const success = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const error = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleAddPart = () => {
    setIsLoading(true);

    if (!categoryName) {
      setIsLoading(false);
      setCategoryError("* category is required");
      return;
    }

    if (!partName) {
      setIsLoading(false);
      setPartError("* part is required");
      return;
    }

    if (!quantity) {
      setIsLoading(false);
      setQuantityError("* quantity is required");
      return;
    }

    if (action === "addwo") {
      const data = {
        partCategoryName: categoryName,
        partName: partName,
      };
      post(
        ADD_SIMPLE_PART_AND_CATEGORY,
        data,
        localStorage.getItem("bearerToken")
      ).then((response) => {
        if (response.succeeded) {
          const partObject = {
            partId: response.data,
            partName: partName,
            quantity: Number(quantity),
          };

          dispatch(addProjectedPart(partObject));
          onHide();

          setIsLoading(false);
          setPartId(response.data);

          if (partId && quantity) {
            const partObject = {
              partId: partId,
              partName: partName,
              quantity: Number(quantity),
            };

            dispatch(addProjectedPart(partObject));
            success(response.messages[0]);
            setCategoryName("");
            setPartName("");
            setQuantity("");
            onHide();
          }
        } else {
          setIsLoading(false);
          error(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        }
      });
    } else if (action === "addticketusedparts") {
      const data = {
        ticketAssetId: ticketAssetId,
        partCategoryName: categoryName,
        partName: partName,
        quantityUsed: quantity,
      };
      post(
        ADD_TICKET_ASSET_USED_SIMPLE_PARTS_AND_CATEGORY,
        data,
        localStorage.getItem("bearerToken")
      ).then((response) => {
        if (response.succeeded) {
          setIsLoading(false);
          setPartId(response.data);

          const newPartsData = {
            id: partId,
            partName: partName,
            quantityUsed: quantity,
            // partSerialNumber: selectedPartNumber,
          };

          const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList?.map((asset) => {
              if (asset.partsUsedList) {
                return {
                  ...asset,
                  partsUsedList: [...asset.partsUsedList, newPartsData],
                };
              }
              return asset;
            });

          setTicketSummary((prevTicketSummary) => ({
            ...prevTicketSummary,
            assetSummary: {
              ...prevTicketSummary.assetSummary,
              workOrderAssetList: updatedWorkOrderAssetList,
            },
          }));
          success(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        } else {
          setIsLoading(false);
          error(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        }
      });
    } else if (action === "addticketreturnedparts") {
      const data = {
        ticketAssetId: ticketAssetId,
        partCategoryName: categoryName,
        partName: partName,
        quantityReturned: quantity,
      };
      post(
        ADD_TICKET_ASSET_RETURNED_SIMPLE_PARTS_AND_CATEGORY,
        data,
        localStorage.getItem("bearerToken")
      ).then((response) => {
        console.log(response);
        if (response.succeeded) {
          setIsLoading(false);
          setPartId(response.data);

          const newPartsData = {
            id: partId,
            partName: partName,
            quantityReturned: quantity,
            // partSerialNumber: selectedPartNumber,
          };

          const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList.map((asset) => {
              if (asset.partsReturnedList) {
                return {
                  ...asset,
                  partsReturnedList: [...asset.partsReturnedList, newPartsData],
                };
              }
              return asset;
            });

          setTicketSummary((prevTicketSummary) => ({
            ...prevTicketSummary,
            assetSummary: {
              ...prevTicketSummary.assetSummary,
              workOrderAssetList: updatedWorkOrderAssetList,
            },
          }));
          success(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        } else {
          setIsLoading(false);
          error(response.messages[0]);
          setCategoryName("");
          setPartName("");
          setQuantity("");
          onHide();
        }
      });
    } else if (action === "adddiagnosisprojectedparts") {
      const payload = {
        requestAssetId: diagnosisSummary?.requestDetailSummary?.requestAssetId,
        partCategoryName: categoryName,
        partName: partName,
        quantityProjected: quantity,
      };

      post(
        ADD_PROJECTED_SIMPLE_PARTS_AND_CATEGORY,
        payload,
        localStorage.getItem("bearerToken")
      )
        .then((response) => {
          if (response.succeeded === true) {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.success(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            const newPartsData = {
              id: 1,
              partId: partId,
              partName: partName,
              quantityProjected: quantity,
              requestAssetId:
                diagnosisSummary?.requestDetailSummary?.requestAssetId,
              //partSerialNumber: selectedPartNumber,
            };
            setDiagnosisSummary((prevDiagnosisSummary) => ({
              ...prevDiagnosisSummary,
              requestPartsSummary: [
                ...prevDiagnosisSummary.requestPartsSummary,
                newPartsData,
              ],
            }));
          } else {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((error) => {
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
          toast.error("An error occured", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        })
        .finally(() => {
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
        });
    }
  };
  const formState = useSelector((state) => state.form);
  const { data: categoryData } = usefetchData(
    ["parts-categories"],
    `/Parts/GetPartForTicketCategories`,
    {},
    "Couldn't get parts data. Please try again!",
    true
  );
  useEffect(() => {
    get(GET_PARTS_CATEGORY_ON_ADDPARTS, localStorage.getItem("bearerToken"))
      .then((response) => {
        setNewPartCategory(response); // Update state with new categories
      })
      .catch((err) => {
        console.error(err);
      });
    dispatch(setPartCategories(newPartCategory));
    console.log(newPartCategory);

    // reloadCategory();
    dispatch(setPartCategories(categoryData));
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Add Parts Category</span>
              <span className="fs-14" style={{ color: "#72777A" }}>
                Select parts and quantities required.
              </span>
            </div>
            <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <span className="fw-medium pb-2 fs-14">Part Category</span>
                  </div>
                  <div
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      marginBottom: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {categoryError}
                  </div>
                </div>

                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                  }}
                  placeholder="Enter category name"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <label className="fw-medium pb-2">Part</label>
                  <div
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      marginBottom: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {partError}
                  </div>
                </div>

                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                  }}
                  placeholder="Enter part name"
                  value={partName}
                  onChange={(e) => setPartName(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <label className="fw-medium pb-2 fs-14">Quantity</label>
                  <span
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      marginBottom: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {quantityError}
                  </span>
                </div>

                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                  }}
                  placeholder="Enter quantity required"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="cancel-btn" onClick={onHide}>
                Cancel
              </button>
              {isLoading ? (
                <Spinner
                  animation="border"
                  style={{
                    color: "#D57D2A",
                  }}
                />
              ) : (
                <button className="delate-btn" onClick={handleAddPart}>
                  Add
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddQuickCategoryModal;
