import React, { useContext, useEffect, useState, useRef } from "react";
import { Accordion, Table } from "react-bootstrap";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";
import CheckIcon from "../../../Assets/Icons/CheckIcon";
import ApproveQuoteModal from "../../WorkOrders/Modal/ApproveQuoteModal";
import QuotesDownload, { downloadPDF } from "./QuotesDownload";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { useReactToPrint } from "react-to-print";
import logo from "../../../Assets/Images/logo.png";
import { FaAngleLeft } from "react-icons/fa6";
import { useDispatch } from "react-redux";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const [company, setCompany] = useState({});
  useEffect(() => {
    setCompany(JSON.parse(localStorage.getItem("company")));
  }, []);
  const dispatch = useDispatch();
  return (
    <>
      <div className="quotes-pad" id="quotes-pad" ref={ref}>
        <div className="auto-gap">
          <div className="pad-header d-flex flex-wrap justify-content-between align-items-center">
            <div className="pad-logo">
              <img src={logo} alt={logo} />
            </div>
            <address className="fs-13 text-black lh-base text-end">
              <div className="fs-24 text-black text-uppercase fw-bold">
                quote
              </div>
              {props?.quote?.quotingTeamName}
              <br />
              {props?.quote?.quotingTeamPhoneNumber}
              <br />
              {props?.quote?.quotingTeamEmail} <br />
              {props?.quote?.quotingTeamAddress1}
              {props?.quote?.quotingTeamAddress2}
            </address>
          </div>
          <hr />
          <div className="quotes-total d-flex flex-wrap justify-content-between align-items-center">
            <div className="quotes-name d-flex flex-wrap gap-4 align-items-center">
              <div className="lh-sm">
                <div className="fs-13 fw-medium">{company?.companyName}</div>
                <div className="fs-11">{company?.address1}</div>
                <div className="fs-12">
                  {company?.street}, {company?.city}, {company?.country}
                </div>
              </div>
              <div className="lh-sm">
                <div className="fs-13 fw-medium">
                  Quotation - {props?.quote?.quoteRef}
                </div>
                <div className="fs-12">
                  {new Date(props?.quote?.rfqCreateDate).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  )}
                </div>
              </div>
            </div>
            <div
              className="total-price fs-20 fw-medium"
              style={{ color: "#D57D2A" }}
            >
              <b className="pe-4">Total</b>{" "}
              {company?.currency && props?.quote?.total
                ? company.currency + " " + props?.quote?.total.toLocaleString()
                : "N/A"}
            </div>
          </div>
          <hr />
          <Table responsive style={{ background: "none" }}>
            <thead>
              <tr>
                <th>Item Description</th>
                <th>Qty</th>
                <th>Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {props?.quote?.quoteItems?.map((item) => (
                <tr key={item.item}>
                  <td>{item?.description}</td>
                  <td>{item?.quantity}</td>
                  <td>
                    {company.currency + " " + item?.unitCost.toLocaleString()}
                  </td>
                  <td>
                    {company.currency + " " + item?.totalCost.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <hr />
          <div className="nots-pard">
            <div className="">
              <div className="fs-11">Notes</div>
              <div className="fs-10 pt-2">{props?.quote?.notes}</div>
            </div>
            <ul>
              <li>
                <span>Grand Total</span>
                <span>
                  {company?.currency && props?.quote?.total
                    ? company.currency +
                      " " +
                      props?.quote?.total.toLocaleString()
                    : "N/A"}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="quotes-footer">
          <span>Prepared By: {props?.quote?.quotingUserName}</span>
        </div>
      </div>
    </>
  );
});

const ViewRequestQuotes = () => {
  const { request, setRequest } = useContext(RequestDetailsContext);
  const [modalShow, setModalShow] = useState(false);
  const [showQuotesDownload, setShowQuotesDownload] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [quote, setQuote] = useState({});
  const [quoteId, setQuoteId] = useState(0);
  const handleToggleQuotesDownload = (index) => {
    setQuote(request?.requestQuoteList[index]);
    setShowQuotesDownload((prevShowQuotesDownload) => !prevShowQuotesDownload);
    setShowBackButton((prevShowBackButton) => !prevShowBackButton);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (shouldPrint && quote) {
      handlePrint();
      setShouldPrint(false); // Reset the flag
    }
  }, [shouldPrint, quote]);
  const handleDownloadClick = (index) => {
    setQuote(request?.requestQuoteList[index]);
    setShouldPrint(true);
  };

  const [companyDetails, setCompanyDetails] = useState({});
  useEffect(() => {
    setCompanyDetails(JSON.parse(localStorage.getItem("company")));
  }, []);
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        {showBackButton && (
          <button
            onClick={handleToggleQuotesDownload}
            className="fs-13 fw-bold mb-4 d-flex align-items-center gap-2"
            style={{ color: "#D57D2A" }}
          >
            <FaAngleLeft className="fs-20" /> BACK TO QUOTES LIST
          </button>
        )}
        <div className="washroom-title">
          <div className="fs-13 fw-medium">QUOTE DETAILS </div>
          <div className="fs-13 fw-bold">
            Quote Deadline:{" "}
            <button className="primary-text fw-bold">
              {request?.requestQuoteList?.length > 0
                ? new Date(
                    request?.requestQuoteList[0]?.deadLineDate
                  ).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                : "Not Available"}
            </button>
          </div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* JOB CARDS */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">
                  {request?.requestQuoteList?.length > 0
                    ? "QUOTES INFORMATION"
                    : "QUOTES INFORMATION NOT AVAILABLE"}
                </div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0 pb-5">
                <div
                  className={`table-responsive-sm request_table ${
                    showQuotesDownload ? "d-none" : ""
                  }`}
                  style={{ maxWidth: "100%" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Contractor</th>
                        <th scope="col">Quote Amount</th>
                        <th scope="col">Prepared By</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {request?.requestQuoteList?.map((quote, index) => (
                        <tr key={index}>
                          <td>{quote?.quotingTeamName}</td>
                          <td>
                            {companyDetails?.currency && quote?.total
                              ? companyDetails.currency +
                                " " +
                                quote?.total.toLocaleString()
                              : "N/A"}
                          </td>
                          <td>{quote?.quotingUserName}</td>
                          <td>
                            <div className="button-con gap-3">
                              {claims("Can_Approve_Quotes") &&
                                quote?.isSubmitted === true &&
                                request?.requestSummaryDetailDto
                                  ?.workOrderId === null && (
                                  <button
                                    onClick={() => {
                                      setQuoteId(quote?.quoteId);
                                      setModalShow(true);
                                    }}
                                  >
                                    <CheckIcon />
                                  </button>
                                )}
                              <button
                                onClick={() =>
                                  handleToggleQuotesDownload(index)
                                }
                              >
                                <EyesIcon />
                              </button>
                              <button
                                onClick={() => handleDownloadClick(index)}
                                className="pdf_download"
                              >
                                <DownloadIcon />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {showQuotesDownload && <QuotesDownload quote={quote} />}
                <div style={{ display: "none" }}>
                  <ComponentToPrint quote={quote} ref={componentRef} />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <ApproveQuoteModal
        quoteId={quoteId}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ViewRequestQuotes;
