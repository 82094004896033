import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DownIcon from "../../../Assets/Icons/DownIcon";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import usefetchData from "../../../hooks/useFetchData";
import {
  setTicketDescription,
  setTicketTitle,
  setAssetCategory,
  setCategory,
  setLocation,
  setAssignedTeam,
  setAssignedUser,
  addSelectedAssets,
  clearSelectedAssets,
  setTicketPriority,
  addAssetListId,
  removeAssetListId,
  removeSelectedAsset,
  setTicketSLA,
  setExpectedTimeValue,
  setResponseType,
  setSelectedExpectedTime,
  setSelectedExpectedTimeId,
  setExpectedTime2Value,
  setSelectedExpectedTime2,
  setSelectedExpectedTime2Id,
  setEstimateTimeValue,
  setSelectedEstimateType,
  setSelectedEstimateTypeId,
  setSignatureRequired,
  setPTWRequired,
  clearAssetListIds,
  resetForm,
} from "../../../redux/formSlice";
import {
  GET_ASSET_BY_LOCATIO_AND_ASSET_CATEGORY,
  GET_ASSET_CATEGORIES,
  GET_SIMPLE_LOCATION_LIST,
} from "../../../utls/constants";
import useMutateData from "../../../hooks/useMutateData";
import { Form, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import SlaCheckModal from "../Modal/SlaCheckModal";
import { PiWarningCircleFill } from "react-icons/pi";
import Astericks from "../../Common/Asterick";
import { styled, Switch } from "@mui/material";

const WorkOrderAssets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.form);
  const [modalShow, setModalShow] = React.useState(false);

  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    location: "Select",
    assetCategory: "Select",
    assignAdditionalTeam: "Select",
  });

  const [selectedLocation, setSelectedLocation] = useState(
    formState.location || "Select"
  );
  const [selectedLocationId, setSelectedLocationId] = useState(
    formState.locationId
  );
  const [selectedAssetCategory, setSelectedAssetCategory] = useState(
    formState.assetCategory || "Select"
  );
  const [selectedAssetCategoryId, setSelectedAssetCategoryId] = useState(
    formState.assetCategoryId
  );

  //location search input
  const [locationSearch, setLocationSearch] = useState("");
  //asset category search input
  const [assetSearch, setAssetSearch] = useState("");
  //asset search input
  const [assetCheckSearch, setAssetCheckSearch] = useState("");

  // show all teams state variables
  const [showAllTeams, setShowAllTeams] = useState(false);
  const [isInternal, setIsInternal] = useState(false);

  const routeState = {
    locationId: selectedLocationId,
    categoryofworkId: selectedAssetCategoryId,
  };

  const handleChecklistSelect = (event) => {
    const assetId = parseInt(event.target.value);
    const assetName = event.target.id;
    if (event.target.checked) {
      dispatch(addAssetListId(assetId));
      dispatch(addSelectedAssets(assetName));
    } else {
      dispatch(removeAssetListId(assetId));
      dispatch(removeSelectedAsset(assetName));
    }
  };

  const handleLocationSelect = (eventKey, event) => {
    const location = locationData.find(
      (item) => item.locationName === eventKey
    );
    setSelectedLocation(location.locationName);
    setSelectedLocationId(location.id);
    setSelectValue({ ...selectValue, location: location.locationName });
    dispatch(
      setLocation({ location: location.locationName, locationId: location.id })
    );
    setLocationSearch("");
  };

  const handleAssetCategorySelect = (eventKey, event) => {
    dispatch(clearSelectedAssets());
    dispatch(clearAssetListIds());
    const assetCategory = assetData.find(
      (item) => item.assetCategoryName === eventKey
    );
    setSelectedAssetCategory(assetCategory.assetCategoryName);
    setSelectedAssetCategoryId(assetCategory.id);
    setSelectValue({
      ...selectValue,
      assetCategory: assetCategory.assetCategoryName,
    });
    setAssetSearch("");
    dispatch(
      setAssetCategory({
        assetCategory: assetCategory.assetCategoryName,
        assetCategoryId: assetCategory.id,
      })
    );
    setAssetSearch("");
  };

  const { data: locationData } = usefetchData(
    ["location"],
    `${GET_SIMPLE_LOCATION_LIST}`,
    {},
    "Couldn't get location data. Please try again!",
    true
  );

  const { data: assetData } = usefetchData(
    ["assets"],
    `${GET_ASSET_CATEGORIES}`,
    {},
    "Couldn't get asset category data. Please try again!",
    true
  );

  const { data: assetCheckData } = usefetchData(
    ["asset-check", selectedLocationId, selectedAssetCategoryId],
    `${GET_ASSET_BY_LOCATIO_AND_ASSET_CATEGORY(
      selectedLocationId,
      selectedAssetCategoryId
    )}`,
    {},
    "Couldn't get asset data. Please try again!",
    !!selectedLocationId && !!selectedAssetCategoryId
  );
  const { data: priority } = usefetchData(
    ["priority"],
    `/Tickets/GetAllTicketPriorities`,
    {},
    "Couldn't get priority data. Please try again!",
    true
  );
  const handlePriorityClick = (items) => {
    const pri = priority.find((item) => item.ticketPrioritiesName === items);
    dispatch(
      setTicketPriority({
        priority: pri.ticketPrioritiesName,
        priorityId: pri.id,
      })
    );
  };
  // Filter location data
  const filteredLocationData =
    locationData &&
    locationData.filter((item) => {
      return item.locationName
        .toLowerCase()
        .includes(locationSearch.toLowerCase());
    });

  // Filter asset data
  const filteredAssetData =
    assetData &&
    assetData.filter((item) => {
      return item.assetCategoryName
        .toLowerCase()
        .includes(assetSearch.toLowerCase());
    });

  // Filter asset check data
  const filteredAssetCheckData =
    assetCheckData &&
    assetCheckData.filter((item) => {
      return item.assetName
        .toLowerCase()
        .includes(assetCheckSearch.toLowerCase());
    });

  // form validation
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formState.location) newErrors.location = "Location is required.";
    if (!formState.assetCategory)
      newErrors.assetCategory = "Asset Category is required.";
    if (!formState.selectedAssets.length)
      newErrors.selectedAssets = "At least one asset must be selected.";
    if (!formState.ticketTitle) newErrors.ticketTitle = "Title is required.";
    if (!formState.ticketDescription)
      newErrors.ticketDescription = "Description is required.";
    if (!formState.ticketPriority)
      newErrors.ticketPriority = "Priority is required.";
    if (!formState.assignedTeam.name)
      newErrors.assignedTeam = "Assigned Team is required.";
    if (!formState.categoryOfWork)
      newErrors.categoryOfWork = "Category of Work is required.";
    return newErrors;
  };

  const validateModalForm = () => {
    const newErrors = {};

    if (
      formState.ticketSLA?.responseType === "FullJob" &&
      !formState.estimateTimeValue
    )
      newErrors.estimateTimeValue = "Estimated Time is required.";
    if (
      formState.ticketSLA?.responseType === "FullJob" &&
      (!formState.selectedEstimateType ||
        formState.selectedEstimateType === "Select")
    )
      newErrors.selectedEstimateType = "Estimated Time Type is required.";
    if (
      formState.ticketSLA?.responseType === "ResponseResolution" &&
      !formState.expectedTimeValue
    )
      newErrors.expectedTimeValue = "Expected Response Time is required.";
    if (
      formState.ticketSLA?.responseType === "ResponseResolution" &&
      (!formState.selectedExpectedTime ||
        formState.selectedExpectedTime === "Select")
    )
      newErrors.selectedExpectedTime =
        "Expected Response Time Type is required.";
    if (formState.ticketSLA?.responseType === "ResponseResolution") {
      // Validate Resolution Time (either selectedExpectedTime2 or expectedResolutionTime should have a value)
      if (
        !formState.selectedExpectedTime2 &&
        !formState?.ticketSLA?.expectedResolutionTime
      ) {
        newErrors.expectedTime2Value = "Expected Resolution Time is required.";
      }

      // Validate Resolution Time Type (either selectedExpectedTime2 or expectedResolutionTimeCalendarType should have a value)
      if (
        (!formState.selectedExpectedTime2 ||
          formState.selectedExpectedTime2 === "Select") &&
        (!formState?.ticketSLA?.expectedResolutionTimeCalendarType ||
          formState?.ticketSLA?.expectedResolutionTimeCalendarType === "Select")
      ) {
        newErrors.selectedExpectedTime2 =
          "Expected Resolution Time Type is required.";
      }
    }
    return newErrors;
  };

  const handleNextClick = (e) => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      e.preventDefault();
      setErrors(validationErrors);
    }
  };

  const handleModalCreateClick = (e) => {
    const validationErrors = validateModalForm();
    console.log(validationErrors);
    if (Object.keys(validationErrors)?.length > 0) {
      e.preventDefault();
      setErrors(validationErrors);
    } else {
      onSubmit(e);
    }
  };

  /// new code

  const [selectedTeam, setselectedTeam] = useState("Select");
  const [selectedAssignWorker, setselectedAssignWorker] = useState("Select");
  const [teamSearch, setTeamSearch] = useState("");
  const [workSearch, setWorkSearch] = useState("");
  const [teamId, setTeamId] = useState(null);
  const [assignWorkerSearch, setAssignWorkerSearch] = useState("");
  const [categoryofworkId, setCategoryOfWorkId] = useState(
    formState.categoryOfWorkId
  );

  const [selectedWork, setselectedWork] = useState("Select");
  const locationId = formState.locationId;

  const [estimateTypeSearch, setEstimateTypeSearch] = useState("");
  const [expectedTimeSearch, setExpectedTimeSearch] = useState("");
  const [expectedTime2Search, setExpectedTime2Search] = useState("");
  const [defaultTeamId, setDefaultTeamId] = useState(null); // defaultTeamId:6

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setIsInternal(user.isInternal || false);
    setDefaultTeamId(user.defaultTeamId || null);
  }, []);

  const teamDataUrl = useMemo(() => {
    if (isInternal && showAllTeams) {
      return `/Team/GetAllTeams`;
    } else if (isInternal && !showAllTeams) {
      return `/Team/GetAllTeamsByTeamId/${defaultTeamId}`;
    } else {
      return `/Team/GetTeamsToAssignTicket?locationId=${locationId}&categoryofworkId=${categoryofworkId}`;
    }
  }, [isInternal, showAllTeams, defaultTeamId, locationId, categoryofworkId]);

  const { data: teamData } = usefetchData(
    ["team-data", categoryofworkId, showAllTeams, isInternal],
    teamDataUrl,
    {},
    "Couldn't get team data. Please try again!",
    !!categoryofworkId && !!locationId
  );

  const { data: workData } = usefetchData(
    ["work-data"],
    `/CategoryOfWorks`,
    {},
    "Couldn't get work data. Please try again!",
    true
  );

  const { data: assignWorkerData } = usefetchData(
    ["assign-worker", teamId],
    `/Team/GetAllUsersByTeam/${teamId}`,
    {},
    "Couldn't get team data. Please try again!",
    !!teamId && !!categoryofworkId
  );
  const { data: calendardata } = usefetchData(
    ["calendar-types"],
    `/Checklists/GetCalendarTypes`,
    {},
    "Couldn't get part calendar types. Please try again!",
    true
  );

  const calendarType = calendardata?.data || [];
  const handleTitleChange = (e) => {
    dispatch(setTicketTitle(e.target.value));
  };

  const handleDescriptionChange = (e) => {
    dispatch(setTicketDescription(e.target.value));
  };
  const filteredWorkData =
    workData &&
    workData.filter((item) => {
      return item.categoryOfWorkName
        .toLowerCase()
        .includes(workSearch.toLowerCase());
    });
  const handleWorkSelect = (eventKey) => {
    const category = workData.find(
      (item) => item.categoryOfWorkName === eventKey
    );
    setselectedWork(category.categoryOfWorkName);
    setCategoryOfWorkId(category.id);
    setSelectValue({ ...selectValue, category: category.categoryOfWorkName });
    dispatch(
      setCategory({
        categoryOfWork: category.categoryOfWorkName,
        categoryOfWorkId: category.id,
      })
    );
    setWorkSearch("");
  };

  const filteredTeamData =
    teamData &&
    teamData?.filter((item) => {
      return item.teamName.toLowerCase().includes(teamSearch.toLowerCase());
    });

  const filteredAssignWorkerData =
    assignWorkerData &&
    assignWorkerData?.filter((item) => {
      const firstName = item?.user?.userFirstName?.toLowerCase();
      const lastName = item?.user?.userLastName?.toLowerCase();
      const fullName = `${firstName} ${lastName}`;
      const searchTerm = assignWorkerSearch.toLowerCase();

      return fullName?.includes(searchTerm);
    });

  const handleTeamSelect = (eventKey) => {
    setselectedAssignWorker("Select");
    dispatch(setAssignedUser(null));

    const team = teamData.find((item) => item.teamName === eventKey);
    setselectedTeam(team.teamName);
    setTeamId(team.id);
    setSelectValue({ ...selectValue, assignTeam: eventKey });
    dispatch(setAssignedTeam({ name: team.teamName, id: team.id }));
    setTeamSearch("");
  };

  const handleAssignWorkerSelect = (eventKey) => {
    const worker = assignWorkerData?.find(
      (item) => item?.user?.userFirstName === eventKey
    );
    setselectedAssignWorker(eventKey);
    setSelectValue({ ...selectValue, assignAdditionalTeam: eventKey });
    dispatch(
      setAssignedUser({
        id: worker?.user?.id,
        name: worker?.user?.userFirstName + " " + worker?.user?.userLastName,
      })
    );
    setAssignWorkerSearch("");
  };

  const onSubmit = (e) => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors)?.length > 0) {
      e.preventDefault();
      setErrors(validationErrors);
    } else {
      let files = [];
      formState.files?.map((file) => {
        const fileObj = {
          encodedFile: file?.encodedFile,
          fileName: file?.fileName,
          url: file?.url,
          fileType: file?.fileName.split(".").pop(),
          fileTypeSource: file?.url === "" ? "" : "blob",
        };
        files.push(fileObj);
      });

      const payload = {
        locationId: formState.locationId
          ? parseInt(formState.locationId)
          : null,
        categoryOfWorkId: formState.categoryOfWorkId
          ? parseInt(formState.categoryOfWorkId)
          : null,
        assetListIds: formState.assetListIds.map((id) =>
          id ? parseInt(id) : null
        ),
        ticketTitle: formState.ticketTitle,
        ticketDescription: formState.ticketDescription,

        files: files,
        checklistIds: formState.checklistIds.map((id) =>
          id ? parseInt(id) : null
        ),
        ticketPriorityId: formState.ticketPriorityId
          ? parseInt(formState.ticketPriorityId)
          : null,
        assignedTeam: {
          id: formState.assignedTeam.id
            ? parseInt(formState.assignedTeam.id)
            : null,
          name: formState.assignedTeam.name || "",
        },
        assignedUser:
          formState.assignedUser === null
            ? null
            : {
                id: formState.assignedUser?.id
                  ? parseInt(formState.assignedUser?.id)
                  : null,
                name: formState.assignedUser?.name || "",
              },
        signatureRequiredToCompleteWork:
          formState.signatureRequiredToCompleteWork,
        isPTWRequired: formState.isPTWRequired,
        useSLATime: true,
        estimatedHours: parseInt(formState.estimatedHours)
          ? parseInt(formState.estimatedHours)
          : 0,
        expectedResponseHours: formState.expectedTimeValue
          ? parseInt(formState.expectedTimeValue)
          : null,
        expectedResolutionHours:
          parseInt(formState.expectedTime2Value) ||
          parseInt(formState?.ticketSLA?.expectedResolutionTime) ||
          null,
        estimateTime:
          parseInt(formState.ticketSLA.estimateTime) ||
          parseInt(formState.estimatedHours) ||
          null,
        estimateTimeCalendarTypeID:
          parseInt(formState.ticketSLA.estimateTimeCalendarTypeId) ||
          formState.selectedEstimateTypeId ||
          null,
        expectedResponseTimeCalendarTypeID: formState.selectedExpectedTimeId
          ? parseInt(formState.selectedExpectedTimeId)
          : null,
        expectedResolutionTimeCalendarTypeID:
          parseInt(formState.selectedExpectedTime2Id) ||
          parseInt(
            formState?.ticketSLA?.expectedResolutionTimeCalendarTypeId
          ) ||
          null,
        projectedParts: formState.projectedParts.map((part) => ({
          spareId: part.partId ? parseInt(part.partId) : null,
          quantity: part.quantity ? parseInt(part.quantity) : null,
          locationId: part.locationId ? parseInt(part.locationId) : null,
          partDataSource: "PartForTicket",
        })),
        requestId: formState.selectedRequestId,
      };
      raiseTicket(payload);
    }
  };

  const { mutate: raiseTicket, isLoading } = useMutateData({
    url: `Tickets/RaiseTicket`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        dispatch(resetForm());
        setTimeout(() => navigate("/", 1000));
        // navigate("/work-orders");
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
  });

  const addSla = () => {
    const requestBody = {
      categoryOfWorkId: formState.categoryOfWorkId,
      locationId: formState.locationId,
      ticketPriorityId: formState.ticketPriorityId,
      ticketType: "corrective",
    };
    mutate(requestBody);
  };
  useEffect(() => {
    if (
      formState.categoryOfWorkId &&
      formState.locationId &&
      formState.ticketPriorityId
    ) {
      addSla();
    }
  }, [
    formState.categoryOfWorkId,
    formState.locationId,
    formState.ticketPriorityId,
  ]);
  useEffect(() => {
    if (
      formState.ticketSLA?.slaMode === "Predefined" &&
      formState.ticketSLA?.responseType === "ResponseResolution"
    ) {
      dispatch(setExpectedTimeValue(formState.ticketSLA?.expectedResponseTime));
      dispatch(setResponseType(formState.ticketSLA?.responseType));
      dispatch(
        setSelectedExpectedTime(
          formState.ticketSLA?.expectedResponseTimeCalendarType !== null
            ? formState.ticketSLA?.expectedResponseTimeCalendarType
            : "Select"
        )
      );
      if (formState.ticketSLA?.expectedResponseTimeCalendarType !== null) {
        const calendarObject = calendarType.find(
          (p) =>
            p.name === formState.ticketSLA?.expectedResponseTimeCalendarType
        );
        if (calendarObject) {
          dispatch(setSelectedExpectedTimeId(calendarObject.id));
        }
      }
    }
    // Similar logic for other SLA types...
  }, [formState.ticketSLA]);
  const handleEstimateTypeSelect = (selectedType) => {
    dispatch(setSelectedEstimateType(selectedType));
    const calendarObject = calendarType.find((p) => p.name === selectedType);
    if (calendarObject) {
      dispatch(setSelectedEstimateTypeId(calendarObject.id));
    }
  };

  const handleExpectedTimeSelect = (selectedType) => {
    dispatch(setSelectedExpectedTime(selectedType));
    const calendarObject = calendarType.find((p) => p.name === selectedType);
    if (calendarObject) {
      dispatch(setSelectedExpectedTimeId(calendarObject.id));
    }
  };

  const handleExpectedTime2Select = (selectedType) => {
    dispatch(setSelectedExpectedTime2(selectedType));
    const calendarObject = calendarType.find((p) => p.name === selectedType);
    if (calendarObject) {
      dispatch(setSelectedExpectedTime2Id(calendarObject.id));
    }
  };

  const filteredEstimateTypeData = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(estimateTypeSearch.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });

  const filteredExpectedTimeData = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(expectedTimeSearch.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });

  const filteredExpectedTime2Data = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(expectedTime2Search.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });
  const { mutate } = useMutateData({
    url: `/Tickets/SLATimes`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      dispatch(setTicketSLA(data?.data?.data));
    },
    successMessage: "SLA data success",
    errorMessage: "Could not get SLA data",
    queryKeysToInvalidate: [],
  });

  // handle switch toggle
  const handleShowAllTeamsChange = useCallback((event) => {
    setShowAllTeams(event.target.checked);
  }, []);

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#D57D2A",
      "&:hover": {
        backgroundColor: "rgba(213, 125, 42, 0.08)",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#D57D2A",
    },
  }));

  return (
    <div className="order-details-content pb-lg-4">
      <div className="fs-16 fw-bold col-l-g">ASSIGN WORK</div>
      <hr
        style={{
          border: "none",
          borderTop: "2px solid #e0e0e0",
          margin: "10px 0",
        }}
      />
      <div className="row details-forms-one pt-4">
        <div className="col-md-6">
          <label>Location</label>
          <Dropdown className="select__form" onSelect={handleLocationSelect}>
            <Dropdown.Toggle
              className={`select-title ${
                selectedLocation !== "Select" && formState.location !== ""
                  ? "selected"
                  : ""
              }`}
            >
              {formState.location === "" && selectedLocation === "Select"}
              {formState.location || "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  value={locationSearch}
                  onChange={(e) => setLocationSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </form>
              <div className="dropdown-item-content">
                {filteredLocationData &&
                  filteredLocationData.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.locationName}>
                      {item.locationName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {errors.location && <div className="error">{errors.location}</div>}
        </div>
        <div className="col-md-6">
          <label>Category of Work</label>
          <Dropdown className="select__form" onSelect={handleWorkSelect}>
            <Dropdown.Toggle
              className={`select-title ${
                formState.categoryOfWork != "" &&
                formState.categoryOfWork != undefined
                  ? "selected"
                  : ""
              }`}
            >
              {formState.categoryOfWork || "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  value={workSearch}
                  onChange={(e) => setWorkSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </form>
              <div className="dropdown-item-content">
                {filteredWorkData &&
                  filteredWorkData.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={item.categoryOfWorkName}
                    >
                      {item.categoryOfWorkName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {errors.categoryOfWork && (
            <div className="error">{errors.categoryOfWork}</div>
          )}
        </div>
        <div className="col-md-6">
          <div
            className="d-flex align-items-center gap-4"
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignContent: "center",
              paddingBottom: "8px",
              height: "fit-content",
            }}
          >
            <label style={{ margin: "0", height: "fit-content" }}>
              Assign Team (Primary)
            </label>
            {isInternal && (
              <label
                className="fs-14  d-flex align-items-center gap-3"
                style={{
                  color: "#D57D2A",
                  textDecoration: "underline",
                  cursor: "pointer",
                  padding: "0",
                  margin: "0",
                }}
              >
                Show All Teams
                <StyledSwitch
                  label="Show All Teams"
                  checked={showAllTeams}
                  onChange={handleShowAllTeamsChange}
                  inputProps={{ "aria-label": "Show All Teams" }}
                />
              </label>
            )}
          </div>
          <Dropdown className="select__form" onSelect={handleTeamSelect}>
            <Dropdown.Toggle
              className={`select-title ${
                formState?.assignedTeam?.name != null ? "selected" : ""
              }`}
            >
              {formState?.assignedTeam?.name ?? "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  onChange={(e) => setTeamSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                  value={teamSearch}
                />
              </form>
              <div className="dropdown-item-content">
                {filteredTeamData &&
                  filteredTeamData.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item?.teamName}>
                      {item?.teamName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {errors.assignedTeam && (
            <div className="error">{errors.assignedTeam}</div>
          )}
        </div>
        {/* {formState?.assignedTeam?.name && ( */}
        <div className="col-md-6" style={{ paddingTop: "10px" }}>
          <label style={{ paddingBottom: "7px" }}>Assign Worker</label>
          <Dropdown
            className="select__form"
            onSelect={handleAssignWorkerSelect}
          >
            <Dropdown.Toggle
              className={`select-title ${
                formState?.assignedUser?.name != null ? "selected" : ""
              }`}
            >
              {formState?.assignedUser?.name ?? "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  value={assignWorkerSearch}
                  onChange={(e) => setAssignWorkerSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </form>
              <div className="dropdown-item-content">
                {filteredAssignWorkerData &&
                  filteredAssignWorkerData.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={item?.user?.userFirstName}
                    >
                      {item?.user?.userFirstName} {item?.user?.userLastName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {/* {errors.assignedUser && (
            <div className="error">{errors.assignedUser}</div>
          )} */}
        </div>
        {/* )} */}
        <br />
        <br />
        <div className="fs-16 fw-bold col-l-g">
          WORK DETAILS (This section is auto-populated if created from a work
          request)
        </div>
        <hr
          style={{
            border: "none",
            borderTop: "2px solid #e0e0e0",
            margin: "-15px 0",
            height: "4px",
          }}
        />
        <div className="row details-forms-one ">
          <div className="fs-15 fw-regular">
            Work Request Ref ID:{" "}
            <span className="fw-bold ps-4" style={{ color: "#D57D2A" }}>
              {formState.requestReference === ""
                ? "Not Available"
                : formState.requestReference}
            </span>
          </div>
          <div className="col-md-6">
            <label>Work Order Title</label>
            <input
              type="text"
              value={formState.ticketTitle}
              onChange={handleTitleChange}
              className="select-title"
            />
            {errors.ticketTitle && (
              <div className="error">{errors.ticketTitle}</div>
            )}
          </div>
          <div className="col-md-6 m-0">
            <label>Description</label>
            <textarea
              name="Description"
              value={formState.ticketDescription}
              onChange={handleDescriptionChange}
            ></textarea>
            {errors.ticketDescription && (
              <div className="error">{errors.ticketDescription}</div>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <label>Asset Category</label>
          <Dropdown
            className="select__form"
            onSelect={handleAssetCategorySelect}
          >
            <Dropdown.Toggle
              className={`select-title ${
                selectedAssetCategory !== "Select" &&
                formState.assetCategory !== ""
                  ? "selected"
                  : ""
              }`}
            >
              {formState.assetCategory || "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  onChange={(e) => setAssetSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                  value={assetSearch}
                />
              </form>
              <div className="dropdown-item-content">
                {filteredAssetData &&
                  filteredAssetData.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={item.assetCategoryName}
                    >
                      {item.assetCategoryName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {errors.assetCategory && (
            <div className="error">{errors.assetCategory}</div>
          )}
        </div>
        {/* {formState.location !== "" && formState.assetCategory !== "" && ( */}
        <div className="col-md-6">
          <label>Asset (s)</label>
          <div className="dropdown Checklists-dropdown assets-checklist select__form">
            <button className="btn checklists-btn" data-bs-toggle="dropdown">
              <div>
                Select
                {formState?.selectedAssets.length > 0 && (
                  <span style={{ color: "#000" }}>
                    {"  "}
                    {formState?.selectedAssets.join(", ")}
                  </span>
                )}
              </div>
              <DownIcon />
            </button>
            <ul className="dropdown-menu">
              <form className="dropdown-search ps-0">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  onChange={(e) => setAssetCheckSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                  value={formState?.selectedAsset?.join(", ")}
                />
              </form>
              <ul className="dropdown-item-content">
                {filteredAssetCheckData &&
                  filteredAssetCheckData.map((item, index) => (
                    <li key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item.id}
                          id={item.assetName}
                          checked={formState.assetListIds.includes(item.id)}
                          onChange={handleChecklistSelect}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={item.assetName}
                        >
                          {item.assetName}
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>
            </ul>
          </div>
          {errors.selectedAssets && (
            <div className="error">{errors.selectedAssets}</div>
          )}
        </div>
        {/* )} */}
        <div className="col-md-12">
          <label>Priority</label>
          <ul className="priority-list">
            {priority &&
              priority.map((p) => (
                <li key={p.ticketPrioritiesName}>
                  <button
                    className={
                      formState.ticketPriority === p.ticketPrioritiesName
                        ? "active"
                        : ""
                    }
                    onClick={() => handlePriorityClick(p.ticketPrioritiesName)}
                  >
                    {p.ticketPrioritiesName}
                  </button>
                </li>
              ))}
          </ul>
          {errors.ticketPriority && (
            <div className="error">{errors.ticketPriority}</div>
          )}
        </div>
      </div>
      <div className="details-buttons d-flex justify-content-between">
        <Link
          to="/work-orders/add-work-order/details"
          state={routeState}
          onClick={handleNextClick}
          className="next-btn ms-0"
        >
          Next
        </Link>
        <button
          type="button"
          onClick={(e) => {
            if (formState.ticketSLA?.slaMode?.toLowerCase() === "predefined") {
              if (
                formState.ticketSLA?.expectedResponseTimeCalendarTypeId === 0 ||
                formState.ticketSLA?.expectedResponseTimeCalendarTypeId === null
              ) {
                setModalShow(true);
              } else {
                onSubmit(e);
              }
            } else {
              if (
                formState.ticketSLA?.estimateTimeCalendarTypeId === 0 ||
                formState.ticketSLA?.estimateTimeCalendarTypeId === null
              ) {
                setModalShow(true);
              } else {
                onSubmit(e);
              }
            }
          }}
          className="create-btn ms-0"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Create Work"
          )}
        </button>
      </div>
      {/* <div className="details-buttons pt-lg-5">
        <Link
          to="/work-orders/add-work-order/details"
          className="next-btn mt-lg-5"
          state={routeState}
          onClick={handleNextClick}
        >
          Next
        </Link>
      </div> */}
      <Modal
        onHide={() => {
          dispatch(setSelectedExpectedTimeId(null));
          dispatch(setSelectedExpectedTime(null));
          dispatch(setSelectedExpectedTime2(null));
          dispatch(setSelectedExpectedTime2Id(null));
          dispatch(setExpectedTimeValue(null));
          dispatch(setExpectedTime2Value(null));
          setModalShow(false);
        }}
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div>
              <span className="fs-16 fw-bold">SLA REQUIRED</span>
              <span className="fs-14 ps-3">SLA information missing</span>
            </div>
            <div
              className="delate-warning"
              style={{
                background: "#F1EFEF",
                height: "267px",
                display: "grid",
                alignItems: "center",
                textAlign: "center",
                padding: "20px",
                marginTop: "30px",
                borderRadius: "5px",
              }}
            >
              <div>
                <PiWarningCircleFill
                  style={{ color: "#D57D2A", fontSize: "70px" }}
                />
                <div
                  className="fs-20 fw-medium"
                  style={{ lineHeight: "1.4", marginTop: "10px" }}
                >
                  SLA information is required to create work order. You can add
                  it below and complete work creation or request Admin to add it
                  for you.
                </div>
              </div>
            </div>

            <div className="row completion-con">
              {
                // formState.categoryOfWorkId &&
                //   formState.ticketPriorityId !== null &&
                (formState.ticketSLA?.responseType === "FullJob" ||
                  formState.responseType === "FullJob") && (
                  <div className="col-md-6 mt-4">
                    {/* {estimateTimeError && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
                    <label className="fw-medium pb-2 fs-14">
                      Estimated Time
                      <Astericks />
                    </label>
                    <input
                      className="modal-input-box"
                      type="number"
                      onChange={(e) => {
                        dispatch(setEstimateTimeValue(e.target.value));
                      }}
                      value={formState.estimateTimeValue}
                      readOnly={formState.ticketSLA?.slaMode === "Predefined"}
                      min="0"
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                        fontSize: "15px",
                      }}
                      placeholder="Enter the time it takes"
                      onKeyPress={(event) => {
                        if (event.key === "-") {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.estimateTimeValue && (
                      <div className="error">{errors.estimateTimeValue}</div>
                    )}
                  </div>
                )
              }
              {
                // formState.categoryOfWorkId &&
                //   formState.ticketPriorityId !== null &&
                (formState.ticketSLA?.responseType === "FullJob" ||
                  formState.responseType === "FullJob") && (
                  <div className="col-md-6 mt-4">
                    <label className="fw-medium pb-2 fs-14">
                      Estimated Time Type
                      <Astericks />
                    </label>
                    <Dropdown
                      className="select__form"
                      onSelect={handleEstimateTypeSelect}
                    >
                      <Dropdown.Toggle
                        className={`select-title ${
                          formState.selectedEstimateType !== "Select"
                            ? "selected"
                            : ""
                        }`}
                        disabled={formState.ticketSLA?.slaMode === "Predefined"}
                      >
                        {formState.selectedEstimateType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="dropdown-item-content">
                          {filteredEstimateTypeData.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={item.name}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    {errors.selectedEstimateType && (
                      <div className="error">{errors.selectedEstimateType}</div>
                    )}
                  </div>
                )
              }
              {(formState?.ticketSLA?.responseType === "ResponseResolution" ||
                formState?.responseType === "ResponseResolution") && (
                <div className="col-md-6 mt-4">
                  {/* {expectedTimeError && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
                  <label className="fw-medium pb-2 fs-14">
                    Expected Response Time
                    <Astericks />
                  </label>
                  <input
                    className="modal-input-box"
                    type="number"
                    onChange={(e) => {
                      dispatch(setExpectedTimeValue(e.target.value));
                    }}
                    value={formState.expectedTimeValue}
                    readOnly={formState.ticketSLA?.slaMode === "Predefined"}
                    min="0"
                    style={{
                      background: "#F1EFEF",
                      width: "100%",
                      border: "0",
                      height: "40px",
                      borderRadius: "5px",
                      padding: "0 10px",
                      fontSize: "15px",
                    }}
                    placeholder="Enter the time it takes"
                    onKeyPress={(event) => {
                      if (event.key === "-") {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.expectedTimeValue && (
                    <div className="error">{errors.expectedTimeValue}</div>
                  )}
                </div>
              )}
              {(formState.ticketSLA?.responseType === "ResponseResolution" ||
                formState.responseType === "ResponseResolution") && (
                <div className="col-md-6 mt-4">
                  {/* {expectedTimeCalendarError && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
                  <label className="fw-medium pb-2 fs-14">
                    Expected Response Time Type
                    <Astericks />
                  </label>
                  <Dropdown
                    className="select__form"
                    onSelect={handleExpectedTimeSelect}
                  >
                    <Dropdown.Toggle
                      className={`select-title ${
                        formState.selectedExpectedTime != null ? "selected" : ""
                      }`}
                      disabled={formState.ticketSLA?.slaMode === "Predefined"}
                    >
                      {formState.selectedExpectedTime || "Select"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-item-content">
                        {filteredExpectedTimeData.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors.selectedExpectedTime && (
                    <div className="error">{errors.selectedExpectedTime}</div>
                  )}
                </div>
              )}
              {(formState.ticketSLA?.responseType === "ResponseResolution" ||
                formState.responseType === "ResponseResolution") && (
                <div className="col-md-6 mt-4">
                  {/* {expectedTime2Error && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
                  <label className="fw-medium pb-2 fs-14">
                    Expected Resolution Time
                    <Astericks />
                  </label>
                  <input
                    className="modal-input-box"
                    type="number"
                    onChange={(e) => {
                      dispatch(setExpectedTime2Value(e.target.value));
                    }}
                    value={
                      formState.expectedTime2Value ||
                      formState?.ticketSLA?.expectedResolutionTime
                    }
                    readOnly={formState.ticketSLA?.slaMode === "Predefined"}
                    min="0"
                    style={{
                      background: "#F1EFEF",
                      width: "100%",
                      border: "0",
                      height: "40px",
                      borderRadius: "5px",
                      padding: "0 10px",
                      fontSize: "15px",
                    }}
                    placeholder="Enter the time it takes"
                    onKeyPress={(event) => {
                      if (event.key === "-") {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.expectedTime2Value && (
                    <div className="error">{errors.expectedTime2Value}</div>
                  )}
                </div>
              )}
              {(formState.ticketSLA?.responseType === "ResponseResolution" ||
                formState.responseType === "ResponseResolution") && (
                <div className="col-md-6 mt-4">
                  <label className="fw-medium pb-2 fs-14">
                    Expected Resolution Time Type
                    <Astericks />
                  </label>
                  <Dropdown
                    className="select__form"
                    onSelect={handleExpectedTime2Select}
                  >
                    <Dropdown.Toggle
                      className={`select-title ${
                        formState.selectedExpectedTime2 !== null
                          ? "selected"
                          : ""
                      }`}
                      disabled={formState.ticketSLA?.slaMode === "Predefined"}
                    >
                      {formState.selectedExpectedTime2 ||
                        formState.ticketSLA
                          .expectedResolutionTimeCalendarType ||
                        "Select"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-item-content">
                        {filteredExpectedTime2Data.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors.selectedExpectedTime2 && (
                    <div className="error">{errors.selectedExpectedTime2}</div>
                  )}
                </div>
              )}
            </div>
            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button
                className="cancel-btn"
                onClick={() => setModalShow(false)}
              >
                Cancel
              </button>
              <button
                className="delate-btn"
                onClick={(e) => {
                  handleModalCreateClick(e);
                }}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Create Work"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WorkOrderAssets;
