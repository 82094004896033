import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../../Services/ApiHelper";
import { APPROVE_REQUEST, REJECT_REQUEST } from "../../../utls/constants";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { act } from "react-dom/test-utils";
import { toast } from "react-toastify";
import { set } from "lodash";
import { Spinner } from "react-bootstrap";

const ApproveRequestModal = (props) => {
  const { request, setRequest } = useContext(RequestDetailsContext);
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const token = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingApprove, setisLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    setComment("");
  }, []);
  const handleApprove = (action) => {
    const endpoint =
      action === "approve"
        ? APPROVE_REQUEST
        : action === "reject"
        ? REJECT_REQUEST
        : action === "approveAndCreateWork"
        ? APPROVE_REQUEST
        : "";
    action === "approve"
      ? setisLoadingApprove(true)
      : action === "reject"
      ? setIsLoadingReject(true)
      : setIsLoading(true);
    let payload = {};
    action === "approve" || action === "approveAndCreateWork"
      ? (payload = { id: id, approvalRemarks: comment })
      : (payload = { id: id, rejectionRemarks: comment });

    post(endpoint, payload, token).then((response) => {
      if (response.succeeded) {
        setRequest({
          ...request,
          requestSummaryDetailDto: {
            ...request.requestSummaryDetailDto,
            statusName:
              action === "approve"
                ? "Approved"
                : action === "reject"
                ? "Declined"
                : action === "approveAndCreateWork"
                ? "Approved"
                : "Pending",
            defaultRequestStatus:
              action === "approve"
                ? "Approved"
                : action === "reject"
                ? "Rejected"
                : action === "approveAndCreateWork"
                ? "Approved"
                : "Pending",
          },
        });
        setIsLoading(false);
        setIsLoadingReject(false);
        setisLoadingApprove(false);
        setComment("");
        props.onHide();

        if (action === "approve") {
          props.handleRequestClick();
          navigate("/work-orders/add-work-order");
        }

        toast.success(response.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      } else {
        setIsLoading(false);
        setIsLoadingReject(false);
        setisLoadingApprove(false);
        setComment("");
        props.onHide();
        toast.error(response.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Approve Request</span>
            <span style={{ color: "#72777A" }} className="fs-14 ps-3">
              Approve or reject request
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2 fs-14">
                Add comments for approving or rejecting the request
              </label>
              <textarea
                name=""
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "end",
              gap: "15px",
            }}
          >
            <button className="border-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoadingReject ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button
                className="cancel-btn"
                onClick={() => {
                  handleApprove("reject");
                }}
              >
                Reject
              </button>
            )}

            {isLoadingApprove ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              claims("Can_Add_Ticket_Portal") && (
                <button
                  className="cancel-btn"
                  onClick={() => {
                    handleApprove("approve");
                    
                  }}
                >
                  Approve & Create Work
                </button>
              )
            )}

            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button
                className="delate-btn"
                onClick={() => {
                  handleApprove("approveAndCreateWork");
                }}
              >
                Approve
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveRequestModal;
