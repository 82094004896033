import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import { HiOutlineMenu } from "react-icons/hi";
import Logo from "../../Assets/Images/logo.svg";
import User from "../../Assets/Images/user.svg";

import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import NotificationIcon from "../../Assets/Icons/NotificationIcon";
import OffIcon from "../../Assets/Icons/OffIcon";
import { IoMdHome } from "react-icons/io";
import { Dropdown } from "react-bootstrap";
import { removeUserSession } from "../../utls/sessionVariables";
import { color } from "framer-motion";
// import user_icon from '../../Assets/Images/user_icon.png';
import Profile from "../../Assets/Images/profile.svg";

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    removeUserSession();
    navigate("/sign-in");
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };
  return (
    <>
      <nav
        className={
          scroll ? "navbar navbar-expand-lg" : "navbar navbar-expand-lg"
        }
      >
        <div className="container">
          <Link className="logo" to="/">
            <img src={Logo} alt="logo" />
          </Link>
          <button
            className="navbar-toggler d-lg-none ms-auto pe-0"
            type="button"
            onClick={toggleOffcanvas}
          >
            <HiOutlineMenu />
          </button>

          <div className={`navbarOffset ${offcanvasOpen ? "show" : ""}`}>
            <div className="offset-header">
              <h5 className="offcanvas-title">
                <img src={Logo} alt="logo" />
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleOffcanvas}
              ></button>
            </div>
            <div className="d-lg-flex align-items-center justify-content-end gap-4">
              <ul className="nav_list">
                <li className="nav-item dropdown">
                  <button
                    className="nav-link dropdown-toggle-caret"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    Work{" "}
                  </button>
                  <ul className="dropdown-menu">
                    {claims("Can_View_Request") && (
                      <li>
                        <Link
                          to="/requests"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Requests
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/work-orders"
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Corrective Work
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/preventive-works"
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Preventive Work
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/work-schedule-templates"
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Schedule Templates
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/diagnosis-quotes"
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Diagnosis & Quotes
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/work-costs"
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Work Costs
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    to="/assets"
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Assets
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/locations"
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Locations
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="" className="nav-link" onClick={toggleOffcanvas}>
                    Assets
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link to="" className="nav-link" onClick={toggleOffcanvas}>
                    Parts
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    to="/inspection-category"
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Continuous Checks
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <button
                    className="nav-link dropdown-toggle-caret"
                    data-bs-toggle="dropdown"
                  >
                    Reports
                  </button>
                  <ul className="dropdown-menu dropdown-menu-report">
                    {/* <div className="report-nav"> */}
                    {claims("Can_View_WorkCostbyCategoryofWorkReport") && (
                      <li>
                        <Link
                          to="/work-costs-category"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Category of Work Costs
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_ContractorPerformanceReport") && (
                      <li>
                        <Link
                          to="/contractor-performance"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Contractor Performance
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_ContractorsPerfomanceSummaryReport") && (
                      <li>
                        <Link
                          to="/contractor-performance-summary"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Contractor Performance Summary
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_WorkCostbyContractorReport") && (
                      <li>
                        <Link
                          to="/work-costs-contractor"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Contractor Work Costs
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_CorrectiveWorkStatusReport") && (
                      <li>
                        <Link
                          to="/corrective-work-status"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Corrective Work Status
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_WorkCostbyEquipmentReport") && (
                      <li>
                        <Link
                          to="/work-costs-equipment"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Equipment Work Costs
                        </Link>
                      </li>
                    )}
                    {claims(
                      "Can_View_OrganisationContractorPerformanceReport"
                    ) && (
                      <li>
                        <Link
                          to="/org-contractor-performance"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Organisation Contractor Performance
                        </Link>
                      </li>
                    )}

                    {claims("Can_View_PMPerformanceReport") && (
                      <li>
                        <Link
                          to="/reports"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          PM Report
                        </Link>
                      </li>
                    )}

                    {/* start heree..................... */}

                    {claims("Can_View_PMvsCMPerformanceReport") && (
                      <li>
                        <Link
                          to="/pm-cm-report"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          PM CM Report
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_PMvsCMbyCategoryofWorkReport") && (
                      <li>
                        <Link
                          to="/pm-cm-category"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          PM CM by Category
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_PMPerformancebyEquipmentReport") && (
                      <li>
                        <Link
                          to="/equipment-pm-performance"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          PM Performance by Equipment
                        </Link>
                      </li>
                    )}
                    {claims("Can_View_PMPerformancebyUserReport") && (
                      <li>
                        <Link
                          to="/user-pm-performance"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          PM Performance by User
                        </Link>
                      </li>
                    )}

                    {claims("Can_View_WorkCostReport") && (
                      <li>
                        <Link
                          to="/work-costs-report"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Work Costs
                        </Link>
                      </li>
                    )}

                    {claims("Can_View_WorkCostbyLocationReport") && (
                      <li>
                        <Link
                          to="/work-costs-location"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Location Work Costs
                        </Link>
                      </li>
                    )}

                    {claims("Can_View_Preventive WorkStatusReport") && (
                      <li>
                        <Link
                          to="/preventive-work-status"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Preventive Work Status
                        </Link>
                      </li>
                    )}

                    {/* {claims("Can_View_PMPerformanceReport") && ( */}
                    {claims("Can_View_RegionalPerformanceReport") && (
                      <li>
                        <Link
                          to="/regional-performance"
                          className="dropdown-item"
                          onClick={toggleOffcanvas}
                        >
                          Regional Performance
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  {/* <button
                    className="nav-link dropdown-toggle-caret"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    More{" "}
                  </button> */}
                  {/* <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/work-orders/work-requested"
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Requests
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/work-orders"
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Work Orders
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="dropdown-item"
                        onClick={toggleOffcanvas}
                      >
                        Schedules
                      </Link>
                    </li>
                  </ul> */}
                </li>
              </ul>
              <div className="navbar-node">
                <button className="search-icon">
                  <SearchIcon />
                </button>
                <button className="notification-icon">
                  <NotificationIcon />
                  <span></span>
                </button>
                <span className="bar-symbol"></span>
                <button className="user-icon fs-14 fw-semibold">
                  {JSON.parse(localStorage.getItem("user")).userFirstName}{" "}
                  {JSON.parse(localStorage.getItem("user")).userLastName}
                  <img src={Profile} style={{ color: "#D57D2A" }} alt="User" />
                </button>
                <button onClick={handleLogout} className="off-icon">
                  <OffIcon />
                </button>
                {/*<Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    variant="Default"
                  >
                    <OffIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ background: "#6C5B51" }}>
                    <Dropdown.Item style={{ textAlign: "center"}} onClick={handleLogout} >
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
      </Dropdown>*/}
              </div>
            </div>
          </div>

          <div
            className={`${offcanvasOpen ? "show offcanvas-backdrop fade" : ""}`}
            onClick={toggleOffcanvas}
          ></div>
        </div>
      </nav>
      <div className="phone-bottom-menu">
        <ul className="navbar-node">
          <li>
            <Link to="/" className="dashboard-icon">
              <IoMdHome />
            </Link>
          </li>
          <li>
            <button className="search-icon">
              <SearchIcon />
            </button> 
          </li>
          <li>
            <button className="user-icon fs-14 fw-semibold">
              <img src={Profile} alt="User" />
              {JSON.parse(localStorage.getItem("user")).userFirstName} &nbsp;
              {JSON.parse(localStorage.getItem("user")).userLastName}
            </button>
          </li>
          <li>
            <button className="notification-icon">
              <NotificationIcon />
              <span></span>
            </button>
          </li>
          <li>
            {/*<Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                variant="Default"
                content=""
              >
                <OffIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ background: "#6C5B51" }}>
                <Dropdown.Item className="drop-down-item" onClick={handleLogout} >
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
      </Dropdown>*/}
            <button onClick={handleLogout} className="off-icon">
              <OffIcon />
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
