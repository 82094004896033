import { progress } from "framer-motion";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_TICKET_ASSET_USED_SIMPLE_PARTS,
  ADD_TICKET_USED_PARTS,
  GET_ALL_PARTS,
  GET_PART_BY_CATEGORY,
  GET_PART_FOR_TICKET_CATEGORIY,
  GET_PARTS_FOR_TICKET,
  GET_SIMPLE_LOCATION_LIST,
} from "../../../utls/constants";
import Astericks from "../../Common/Asterick";

const AddUsedPartsModal = (props) => {
  const [selectedPart, setSelectedPart] = useState("Select Part");
  const [selectedPartNumber, setSelectedPartNumber] = useState("");
  const [selectedPartId, setSelectedPartId] = useState(0);
  const [selectedPartLocationId, setSelectedPartLocationId] = useState(0);
  const [selectedPartLocation, setSelectedPartLocation] = useState(
    "Select location of part"
  );
  const [selectedCategory, setSelectedCategory] = useState(
    "Select Part Category"
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [addUsedPartsShow, setAddUsedPartsShow] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [location, setLocation] = useState([]);
  const [parts, setParts] = useState([]);
  const token = localStorage.getItem("bearerToken");
  //get search input
  const [partSearch, setPartSearch] = useState("");
  const [partLocationSearch, setPartLocationSearch] = useState("");
  const [categoriesSearch, setCategoriesSearch] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [categories, setCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    get(GET_SIMPLE_LOCATION_LIST, token)
      .then((data) => setLocation(data))
      .catch((error) => console.log(error));

    get(GET_PARTS_FOR_TICKET, token)
      .then((response) => setParts(response))
      .catch((error) => console.log(error));

    get(GET_PART_FOR_TICKET_CATEGORIY, token)
      .then((data) => setCategories(data))
      .catch((error) => console.log(error));

    setSelectedPart("Select Part");
    setSelectedCategory("Select Part Category");
    setSelectedPartLocation("Select location of part");
    setQuantity(0);
  }, [props.show]);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  //filter part data  by search input
  const filteredPartData = parts.filter((item) =>
    item?.name.toLowerCase().includes(partSearch.toLowerCase())
  );
  //filter part location data  by search input
  const filteredPartLocationData = location.filter((item) =>
    item.locationName.toLowerCase().includes(partLocationSearch.toLowerCase())
  );
  const filteredCategoryData = categories.filter((item) =>
    item.name.toLowerCase().includes(categoriesSearch.toLocaleLowerCase())
  );

  //handle select part
  const handlePartSelect = (eventKey) => {
    const partObject = parts.find((p) => p.name === eventKey);

    if (partObject) {
      setSelectedPart(eventKey);
      setSelectedPartId(partObject.id);
      setSelectedPartNumber(partObject.partNumber);
    }

    setPartSearch("");
  };
  const handlePartLocationSelect = (eventKey) => {
    const locationObject = location.find((l) => l.locationName === eventKey);

    if (locationObject) {
      setSelectedPartLocation(eventKey);
      setSelectedPartLocationId(locationObject.id);
    }
    setPartLocationSearch("");
  };

  const handleCategorySelect = (eventKey) => {
    const categoryObj = categories.find((l) => l.name === eventKey);

    if (categoryObj) {
      setSelectedCategory(eventKey);
      setSelectedCategoryId(categoryObj.id);
    }
    setCategoriesSearch("");
    get(
      GET_PART_BY_CATEGORY(categoryObj.id),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      setParts(response);
    });
  };

  const [partError, setPartError] = useState(null);
  const [locError, setLocError] = useState(null);
  const [quantError, setQuantError] = useState(null);

  const validateInputs = () => {
    let isValid = true;

    setPartError(null);
    setLocError(null);
    setQuantError(null);

    if (selectedPart === "Select Part") {
      setPartError("Required *");
      isValid = false;
    }

    if (!selectedCategoryId) {
      setLocError("Required *");
      isValid = false;
    }

    if (!quantity) {
      setQuantError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }

    setIsLoading(true);
    const payload = {
      ticketAssetId: props.ticketAssetId,
      partId: selectedPartId,
      quantityUsed: quantity,
    };

    post(ADD_TICKET_ASSET_USED_SIMPLE_PARTS, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          setIsLoading(false);
          props.onHide();
          setSelectedPart("Select Part");
          setSelectedPartLocation("Select location of part");
          setQuantity(0);

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const newPartsData = {
            id: response.data,
            partLocationId: selectedPartLocationId,
            locationName: selectedPartLocation,
            partId: selectedPartId,
            partName: selectedPart,
            quantityUsed: quantity,
            ticketAssetId: ticketId,
            partSerialNumber: selectedPartNumber,
          };

          const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList[props.index];

          updatedWorkOrderAssetList.partsUsedList.push(newPartsData);
        } else {
          setIsLoading(false);
          props.onHide();
          setSelectedPart("Select Part");
          setSelectedPartLocation("Select location of part");
          setQuantity(0);

          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        props.onHide();
        setSelectedPart("Select Part");
        setSelectedPartLocation("Select location of part");
        setQuantity(0);
        console.log(error);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.onHide();
        setSelectedPart("Select Part");
        setSelectedPartLocation("Select location of part");
        setQuantity(0);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Used Parts</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Parts used in the work
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Select Part Category
                  <Astericks />
                </label>
                {locError && <p style={{ color: "red" }}>{locError}</p>}
              </div>
              <Dropdown
                className="select__form"
                onSelect={handleCategorySelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedCategory !== "Select Part Category"
                      ? "selected"
                      : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedCategory}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setPartLocationSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredCategoryData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.name}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Select Part
                  <Astericks />
                </label>
                {partError && <p style={{ color: "red" }}>{partError}</p>}
              </div>
              <Dropdown className="select__form" onSelect={handlePartSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPart !== "Select Part" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedPart}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setPartSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.name}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Quantity
                  <Astericks />
                </label>
                {quantError && <p style={{ color: "red" }}>{quantError}</p>}
              </div>
              <input
                className="modal-input-box"
                type="number"
                min={1}
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity required"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUsedPartsModal;
