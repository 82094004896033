import React, { useState, useContext } from "react";
import { Accordion } from "react-bootstrap";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import DeleteRFQModal from "../../WorkOrders/Modal/DeleteRFQModal";
import AddProjectedPartsQuotesModal from "../../WorkOrders/Modal/AddProjectedPartsQuotesModal";
import EditProjectedPartsQuotesModal from "../../WorkOrders/Modal/EditProjectedPartsQuotesModal";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { post } from "../../../Services/ApiHelper";
import { toast } from "react-toastify";
import { DELETE_RFQ_PROJECTEDPART } from "../../../utls/constants";
import AddPartsModal from "../../WorkOrders/Modal/AddPartsModal";
import usefetchData from "../../../hooks/useFetchData";
import { useDispatch } from "react-redux";
import { setPartCategories } from "../../../redux/formSlice";

const ViewDiagnosisQuotesParts = () => {
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [returnedShow, setReturnedShow] = useState(false);
  const [editData, setEditData] = useState({});
  const [itemIdToDelete, setItemIdToDelete] = useState(false);
  const { diagnosisSummary, setDiagnosisSummary, isLoading, setIsLoading } =
    useContext(DiagnosisDetailsContext);
  const deadLineDate = new Date(
    diagnosisSummary?.requestQuoteSummary?.deadLineDate
  );
  const requestAssetId= diagnosisSummary?.requestDetailSummary?.requestAssetId;
  const currentDate = new Date();

  const { data: partCategories } = usefetchData(
    ["parts-categories"],
    `/Parts/GetPartForTicketCategories`,
    {},
    "Couldn't get parts data. Please try again!",
    true
  );

const  dispatch = useDispatch();

dispatch(setPartCategories(partCategories))

  const { data: parts } = usefetchData(
    ["parts"],
    `/Parts/GetPartsForTicket`,
    {},
    "Couldn't get parts data. Please try again!",
    true
  );

  const [selectedParts, setSelectedParts] = useState(diagnosisSummary?.requestPartsSummary);
  
  const [action, setAction] = useState("");
  const handleDelete = async () => {
    setIsLoading(true);
    const bearerToken = localStorage.getItem("bearerToken");
    const endPoint = DELETE_RFQ_PROJECTEDPART;
    const data = { id: itemIdToDelete };

    post(endPoint, data, bearerToken)
      .then((response) => {
        if (response.succeeded === true) {
          setDelateModalShow(false);
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestPartsSummary:
              prevDiagnosisSummary.requestPartsSummary.filter(
                (a) => a.id !== itemIdToDelete
              ),
          }));
        } else {
          setDelateModalShow(false);
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setDelateModalShow(false);
        setIsLoading(false);
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setDelateModalShow(false);
        setIsLoading(false);
      });
  };
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">PARTS DETAILS</div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="1">
            {/* PARTS INFORMATION */}
            <Accordion.Item
              eventKey="1"
              className="request-box horizonScroll pe-0 requestParts"
            >
              <Accordion.Header className="request-box-title pe-3">
                <div className="fs-13 fw-medium">PARTS INFORMATION</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div
                  className="table-responsive-sm request_table"
                  style={{ maxWidth: "100%", height: "auto" }}
                >
                    <div>
                      <div
                        className="fs-15 pb-1 pt-2"
                        style={{ color: "#D57D2A" }}
                      >
                        <b>Projected Parts</b>
                      </div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Parts</th>
                            <th scope="col">Quantity</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {diagnosisSummary?.requestPartsSummary?.map((part, index) => (
                            <tr key={index}>
                              <td>{part.partName}{part.partSerialNumber && ` ${part.partSerialNumber}`}</td>
                              <td className="ps-3">{part.quantityProjected}</td>
                              <td>
                              {(diagnosisSummary?.requestQuoteSummary?.isOpen === true) && 
                              (diagnosisSummary?.requestQuoteSummary?.blockQuoteAfterDeadLine === true ? currentDate < deadLineDate : true) &&
                              (
                                <div className="button-con">
                                  <button
                                    onClick={() => {setEditData(part); setEditModalShow(true);}}
                                  >
                                    <EditIcon2 />
                                  </button>
                                  <button
                                    onClick={() => {setItemIdToDelete(part.id); setDelateModalShow(true);}}
                                  >
                                    <DeleteIcon />
                                  </button>
                                </div>
                              )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  {/* {(diagnosisSummary?.requestQuoteSummary?.isOpen === true)  && 
                  (diagnosisSummary?.requestQuoteSummary?.blockQuoteAfterDeadLine === true ? currentDate < deadLineDate : true) && 
                  ( */}
                  <div className="text-end pe-1">
                    <button
                      className="add-button my-3"
                      onClick={() => {
                        setReturnedShow(true)
                        setAction("adddiagnosisprojectedparts")
                      }}
                    >
                      Add Part
                    </button>
                  </div>
                  {/* )} */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <DeleteRFQModal
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
        onDelete={handleDelete}
      />
      <EditProjectedPartsQuotesModal
        data={editData}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      />
      
      <AddPartsModal
        show={returnedShow}
        onHide={() => setReturnedShow(false)}
        requestAssetId={diagnosisSummary?.requestDetailSummary?.requestAssetId}
        partCategories={partCategories}
        partData={parts}
        selectedParts={diagnosisSummary?.requestPartsSummary}
        action={action}
      />

      {/* <AddProjectedPartsQuotesModal
        requestAssetId={diagnosisSummary?.requestDetailSummary?.requestAssetId}
        show={returnedShow}
        onHide={() => setReturnedShow(false)}
      /> */}
    </>
  );
};

export default ViewDiagnosisQuotesParts;
