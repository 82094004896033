import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
const ViewRequiestParts = () => {
  const { request, setRequest } = useContext(RequestDetailsContext);
  const groupedParts = request?.requestProjectedPartList?.reduce((acc, part) => {
    const { teamID, teamName } = part;
    if (!acc[teamID]) {
      acc[teamID] = {
        teamID,
        teamName,
        parts: []
      };
    }
    acc[teamID].parts.push(part);
    return acc;
  }, {});
console.log(request)
  const transformedProjectedParts = Object?.values(groupedParts);
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">PARTS DETAILS</div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="1">
            {/* PARTS INFORMATION */}
            <Accordion.Item
              eventKey="1"
              className="request-box horizonScroll pe-0 requestParts"
            >
              <Accordion.Header className="request-box-title pe-3">
                <div className="fs-13 fw-medium">
                  {request?.requestProjectedPartList?.length > 0
                    ? "PARTS INFORMATION"
                    : "PARTS INFORMATION NOT AVAILABLE"}
                </div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div
                  className="table-responsive-sm request_table"
                  style={{ maxWidth: "100%" }}
                >
                  {transformedProjectedParts?.map((data, index) => (
                    <div key={data.teamID}>
                      <div className="fs-15 text-black pb-1 pt-2">
                        Projected Parts By: <b>{data?.teamName}</b>
                      </div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Parts</th>
                            <th scope="col">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.parts.map((part, index) => (
                            <tr key={part.id}>
                              <td>{part?.partName}</td>
                              <td className="ps-3">{part?.quantityProjected}</td>
                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

const partsData = [
  {
    id: 0,
    partsBy: "Prowalco Engineering",
    parts: [
      {
        name: "Part A - 005788",
        quantity: 3,
      },
      {
        name: "Part B - 781912",
        quantity: 1,
      },
      {
        name: "Part C - 562782",
        quantity: 1,
      },
    ],
  },
  {
    id: 1,
    partsBy: "Nexgen Engineering",
    parts: [
      {
        name: "Part A - 005788",
        quantity: 3,
      },
      {
        name: "Part B - 781912",
        quantity: 1,
      },
      {
        name: "Part C - 562782",
        quantity: 1,
      },
    ],
  },
  {
    id: 2,
    partsBy: "Rubis Engineering",
    parts: [
      {
        name: "Part A - 005788",
        quantity: 3,
      },
      {
        name: "Part B - 781912",
        quantity: 1,
      },
      {
        name: "Part C - 562782",
        quantity: 1,
      },
    ],
  },
  {
    id: 2,
    partsBy: "Rubis Engineering",
    parts: [
      {
        name: "Part A - 005788",
        quantity: 3,
      },
      {
        name: "Part B - 781912",
        quantity: 1,
      },
      {
        name: "Part C - 562782",
        quantity: 1,
      },
    ],
  },
];

export default ViewRequiestParts;
