import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  addProjectedPart,
  addTicketUsedPart,
  setPartCategories,
} from "../../../redux/formSlice";
import { get, post } from "../../../Services/ApiHelper";

import {
  ADD_PROJECTED_SIMPLE_PART,
  ADD_RFQ_PROJECTEDPART,
  ADD_TICKET_ASSET_RETURNED_SIMPLE_PARTS,
  ADD_TICKET_ASSET_USED_SIMPLE_PARTS,
  ADD_TICKET_RETURNED_PARTS,
  GET_PART_BY_CATEGORY,
  GET_PARTS_CATEGORY_ON_ADDPARTS,
} from "../../../utls/constants";
import AddQuickPartModal from "./AddQuickPartModal";
import AddQuickCategoryModal from "./AddQuickCategoryModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  DiagnosisDetailsContext,
  WorkOrderContext,
} from "../../../Context/Inspections/WorkOrderContext";
import { act } from "react";
import usefetchData from "../../../hooks/useFetchData";

const AddPartsModal = ({
  onHide,
  show,
  // partData,
  partCategories,
  defaultValues,
  selectedParts,
  action,
  ticketAssetId,
  index,
  requestAssetId,
  categories,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (action === "addticketusedparts") {
      setHeader("Add Used Parts");
    } else if (action === "addticketreturnedparts") {
      setHeader("Add Returned Parts");
    }
    setSelectedPart("Select Part");
    setSelectedCategory("Select Category");
    setQuantity("");
    setParts(partData);
    setCategoryError("");
    setQuantityError("");
    setPartError("");
    setPartSearch("");
    setCategorySearch("");
    setIsLoading(false);

    dispatch(setPartCategories(categoryData));
    
    dispatch(setPartCategories(newPartCategory));
  }, [show]);

  const formState = useSelector((state) => state.form);
  const { data: categoryData } = usefetchData(
    ["parts-categories"],
    `/Parts/GetPartForTicketCategories`,

    {},
    "Couldn't get parts data. Please try again!",
    true
  );

  dispatch(setPartCategories(categoryData));

  const [selectedPart, setSelectedPart] = useState("Select Part");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [quantity, setQuantity] = useState("");
  const [quickCategoryModalShow, setQuickCategoryModalShow] = useState(false);
  const [quickPartModalShow, setQuickPArtModalShow] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );

  const [categoryError, setCategoryError] = useState("");
  const [partError, setPartError] = useState("");
  const [quantityError, setQuantityError] = useState("");

  // Part category search input
  const [partSearch, setPartSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");

  const token = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(false);
  const [header, setHeader] = useState("Add Parts");
  const { data: partData } = usefetchData(
    ["parts"],
    `/Parts/GetPartsForTicket`,

    {},
    "Couldn't get parts data. Please try again!",
    true
  );
  const [parts, setParts] = useState(partData);

  // Filter part data
  // remove parts that are already selected

  const [newPartCategory, setNewPartCategory] = useState([]);

  // dispatch(setPartCategories(newPartCategory))

  const uniqueFilteredPartData = parts?.filter(
    (obj) =>
      !selectedParts?.some((obj2) => {
        return obj.name === obj2.partName;
      })
  );

  const filteredPartData =
    uniqueFilteredPartData &&
    uniqueFilteredPartData?.filter((item) => {
      const uniqueData = !selectedParts?.some(
        (part) => part.partId === item.id
      );

      return item.name?.toLowerCase().includes(partSearch.toLowerCase());
    });

  const filteredCategoryData = formState.categories?.filter((item) => {
    return item?.name.toLowerCase().includes(categorySearch.toLowerCase());
  });

  const handlePartSelect = (eventKey) => {
    setSelectedPart(eventKey);
    setPartSearch("");
  };
  const handleCategorySelect = (eventKey) => {
    const selectedCategoryId = formState?.categories?.find(
      (item) => item.name === eventKey
    )?.id;

    setSelectedCategory(eventKey);
    setCategorySearch("");
    get(
      GET_PART_BY_CATEGORY(selectedCategoryId),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      setParts(response);
      console.log(parts);
    });
  };

  const handleAddPart = () => {
    setIsLoading(true);

    if (!selectedPart || selectedPart === "Select Part") {
      setIsLoading(false);
      setPartError("* part is required");
      return;
    }

    if (!quantity || Number(quantity) <= 0) {
      setIsLoading(false);
      setQuantityError("* quantity is required");
      return;
    }

    const part = partData?.find((item) => item.name === selectedPart);
    if (action.toLowerCase() == "addwo") {
      const partObject = {
        partId: part.id,
        partName: part.name,
        quantity: Number(quantity),
      };

      dispatch(addProjectedPart(partObject));
      onHide();
    } else if (action.toLowerCase() == "addticketusedparts") {
      const payload = {
        ticketAssetId: ticketAssetId,
        partId: part.id,
        quantityUsed: Number(quantity),
      };
      post(ADD_TICKET_ASSET_USED_SIMPLE_PARTS, payload, token)
        .then((response) => {
          if (response.succeeded === true) {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.success(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            const newPartsData = {
              id: response.data,
              partId: part.id,
              partName: part.name,
              quantityUsed: quantity,
              ticketAssetId: ticketAssetId,
              // partSerialNumber: selectedPartNumber,
            };

            const updatedWorkOrderAssetList =
              ticketSummary.assetSummary.workOrderAssetList?.map((asset) => {
                if (asset.partsUsedList) {
                  return {
                    ...asset,
                    partsUsedList: [...asset.partsUsedList, newPartsData],
                  };
                }
                return asset;
              });

            setTicketSummary((prevTicketSummary) => ({
              ...prevTicketSummary,
              assetSummary: {
                ...prevTicketSummary.assetSummary,
                workOrderAssetList: updatedWorkOrderAssetList,
              },
            }));
          } else {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
          toast.error("An error occured", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        })
        .finally(() => {
          setIsLoading(false);
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
        });
    } else if (action === "addticketreturnedparts") {
      const payload = {
        ticketAssetId: ticketAssetId,
        partId: part.id,
        quantityReturned: quantity,
      };

      post(ADD_TICKET_ASSET_RETURNED_SIMPLE_PARTS, payload, token)
        .then((response) => {
          if (response.succeeded === true) {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");
            toast.success(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });

            const newPartsData = {
              id: response.data,
              partId: part.id,
              partName: part.name,
              quantityReturned: quantity,
              ticketAssetId: ticketAssetId,
              // partSerialNumber: selectedPartNumber,
            };

            const updatedWorkOrderAssetList =
              ticketSummary.assetSummary.workOrderAssetList.map((asset) => {
                if (asset.partsReturnedList) {
                  return {
                    ...asset,
                    partsReturnedList: [
                      ...asset.partsReturnedList,
                      newPartsData,
                    ],
                  };
                }
                return asset;
              });

            setTicketSummary((prevTicketSummary) => ({
              ...prevTicketSummary,
              assetSummary: {
                ...prevTicketSummary.assetSummary,
                workOrderAssetList: updatedWorkOrderAssetList,
              },
            }));
          } else {
            setIsLoading(false);
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");
            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
          toast.error("An error occured", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (action === "adddiagnosisprojectedparts") {
      const payload = {
        requestAssetId: requestAssetId,
        partId: part.id,
        quantityProjected: quantity,
      };
      post(ADD_PROJECTED_SIMPLE_PART, payload, token)
        .then((response) => {
          if (response.succeeded === true) {
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.success(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            const newPartsData = {
              id: response.data,
              partId: part.id,
              partName: part.name,
              quantityProjected: quantity,
              requestAssetId: requestAssetId,
              //partSerialNumber: selectedPartNumber,
            };
            console.log(newPartsData);
            setDiagnosisSummary((prevDiagnosisSummary) => ({
              ...prevDiagnosisSummary,
              requestPartsSummary: [
                ...prevDiagnosisSummary.requestPartsSummary,
                newPartsData,
              ],
            }));
            /*const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList[props.index];

          updatedWorkOrderAssetList.partsUsedList.push(newPartsData);*/
          } else {
            onHide();
            setSelectedPart("Select Part");
            setSelectedCategory("Select Category");
            setQuantity("");

            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((error) => {
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
          toast.error("An error occured", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        })
        .finally(() => {
          onHide();
          setSelectedPart("Select Part");
          setSelectedCategory("Select Category");
          setQuantity("");
        });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">{header}</span>
              <span className="fs-14" style={{ color: "#72777A" }}>
                Select parts and quantities required.
              </span>
            </div>
            <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
              <div className="col-md-12">
                <div className="d-flex align-items-center gap-4 flexNew">
                  <span className="fw-medium pb-2 fs-14">
                    Select Part Category
                  </span>
                  <span
                    className="fs-14 pb-2 "
                    style={{
                      color: "#D57D2A",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onHide();
                      setQuickCategoryModalShow(true);
                    }}
                  >
                    Add New
                  </span>
                </div>
                <Dropdown
                  className="select__form"
                  onSelect={(eventKey) => handleCategorySelect(eventKey)}
                >
                  <Dropdown.Toggle
                    className={`select-title ${
                      selectedCategory !== "Select Category" ? "selected" : ""
                    }`}
                    style={{ height: "50px" }}
                  >
                    {selectedCategory}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={categorySearch}
                        onChange={(e) => setCategorySearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filteredCategoryData &&
                        filteredCategoryData?.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center gap-4 flexNew">
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      gap: "20px",
                    }}
                  >
                    <span className="fw-medium pb-2 fs-14">Select Part</span>
                    <div
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "14px",
                      }}
                    >
                      {partError}
                    </div>
                  </div>

                  <span
                    className="fs-14 pb-2 "
                    style={{
                      color: "#D57D2A",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onHide();
                      setQuickPArtModalShow(true);
                    }}
                  >
                    Add New
                  </span>
                </div>
                <div
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                ></div>
                <Dropdown className="select__form" onSelect={handlePartSelect}>
                  <Dropdown.Toggle
                    className={`select-title ${
                      selectedPart !== "Select Part" ? "selected" : ""
                    }`}
                    style={{ height: "50px" }}
                  >
                    {selectedPart}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={partSearch}
                        onChange={(e) => setPartSearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filteredPartData &&
                        filteredPartData.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <label className="fw-medium pb-2 fs-14">Quantity</label>
                  <div
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      marginBottom: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {quantityError}
                  </div>
                </div>

                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                  }}
                  placeholder="Enter quantity required"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="cancel-btn" onClick={onHide}>
                Cancel
              </button>
              {isLoading ? (
                <Spinner
                  animation="border"
                  style={{
                    color: "#D57D2A",
                  }}
                />
              ) : (
                <Link to="" className="delate-btn" onClick={handleAddPart}>
                  Add
                </Link>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddQuickPartModal
        onHide={() => setQuickPArtModalShow(false)}
        show={quickPartModalShow}
        partCategories={partCategories}
        partData={partData}
        action={action}
        index={index}
        ticketAssetId={ticketAssetId}
      />

      <AddQuickCategoryModal
        onHide={() => setQuickCategoryModalShow(false)}
        show={quickCategoryModalShow}
        partCategories={newPartCategory}
        action={action}
        index={index}
        ticketAssetId={ticketAssetId}
        // reloadCategory={reloadCategory}
      />
    </>
  );
};

export default AddPartsModal;
